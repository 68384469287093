export default [
  {
    title: "Artículos para Vultr",
    body: "<p>He escrito varios artículos técnicos para Vultr, compartiendo mis conocimientos y experiencia en diversas tecnologías y prácticas de desarrollo. Implementé Clean Code, Docker y Kubernetes para asegurar que los artículos sean claros, concisos y fáciles de entender, facilitando su aplicación por otros desarrolladores. Puedes ver mis publicaciones en el siguiente enlace: <a href='https://docs.vultr.com/author/josé-rafael-gutierrez' target='_blank'>https://docs.vultr.com/author/josé-rafael-gutierrez</a>. Estos artículos cubren una amplia gama de temas, desde la configuración de servidores hasta el desarrollo de aplicaciones y la implementación de mejores prácticas en la nube.</p>",
    tags: ["Clean Code", "Docker", "Kubernetes", "DevOPS", "Linux"],
  },
  {
    title: "Decorcerámica - SAP",
    body: "<p>El desarrollo consistió en integrar el sistema de eCommerce Vtex (<a href='https://www.decorceramica.com/' target='_blank'>https://www.decorceramica.com/</a>) con SAP para gestionar el inventario, los precios, la facturación y la logística. Utilicé TDD para asegurar la funcionalidad desde el inicio y API Rest para una comunicación eficiente. La integración se llevó a cabo utilizando PHP/Laravel, VueJS y una base de datos PostgreSQL.</p>",
    tags: ["TDD", "API Rest", "Laravel", "VueJS", "Vtex", "SAP", "PostgreSQL"],
  },
  {
    title: "FacesCR - Intélisis",
    body: "<p>Se realizó la integración de tienda Vtex (<a href='https://cr.faces.com/' target='_blank'>https://cr.faces.com/</a>) con el ERP Intélisis para gestionar precios, inventario y facturación, además de integrarse con Correos de Costa Rica (<a href='https://correos.go.cr/' target='_blank'>https://correos.go.cr/</a>) para la gestión logística. Se emplearon tecnologías como PHP/Laravel, VueJS y una base de datos MySQL para llevar a cabo esta integración de manera eficiente. Utilicé TDD para asegurar la correcta funcionalidad y microservicios para una arquitectura escalable.</p>",
    tags: [
      "Microservicios",
      "TDD",
      "API Rest",
      "Laravel",
      "Vtex",
      "Intelisis",
      "MySQL",
    ],
  },
  {
    title: "SDK Vtex Api",
    body: "<p>Ésta librería o SDK facilita la comunicación versátil con las API's de Vtex y permite el desarrollo de sistemas de integración con mayor facilidad. Está diseñada para ser utilizada con PHP, Laravel, Symfony y otros frameworks basados en PHP. Se implementó el patrón de diseño Adaptador para lograr la integración de manera más sencilla. Puedes encontrarla en mi cuenta de GitHub: <a href='https://github.com/josefo727/vtex-api' target='_blank'>https://github.com/josefo727/vtex-api</a></p>",
    tags: [
      "Patrón Adaptador",
      "Clean Code",
      "TDD",
      "Laravel",
      "Symfony",
      "PHP",
    ],
  },
  {
    title: "Crédito KBK",
    body: "<p>Se realizó la integración del medio de pago Crédito Karibik en tienda Vtex Legacy, y posteriormente se migró a Vtex IO (<a href='https://karibik.co/pages/credito-kbk' target='_blank'>https://karibik.co/pages/credito-kbk</a>). Este sistema de pago se enfoca en brindar créditos a los clientes afiliados a Karibik, y fue configurado con un Middleware de Integración (NodeJS/Express, MongoDB y VueJS) para la gestión de dichos créditos. Utilicé TDD y un enfoque Event-Driven para asegurar la fiabilidad y procesar transacciones en tiempo real.</p>",
    tags: [
      "Event-Driven",
      "TDD",
      "Laravel",
      "ReactJS",
      "Vtex",
      "MongoDB",
      "NodeJS",
      "Express",
    ],
  },
  {
    title: "OT&V de PMI",
    body: "<p>Se desarrolló el sistema Order Tracking and Visualization para Phillips Morris Internacional con una integración a Vtex para inyectar órdenes de diferentes medios de ventas y tipos. Utilicé un enfoque Event-Driven y el patrón Hexagonal para procesar órdenes en tiempo real. En el backend se utilizó Laravel con TDD para garantizar estabilidad, mientras que en el frontend se empleó Vtex IO y ReactJS para componentes administrativos customizados.</p>",
    tags: [
      "Clean Code",
      "Event-Driven",
      "TDD",
      "Hexagonal",
      "Laravel",
      "ReactJS",
      "Vtex",
    ],
  },
  {
    title: "Kaiowa",
    body: "<p>Se desarrolló e implementó un operador de medios de crédito para tiendas Vtex afiliadas, permitiendo la gestión de planes de crédito personalizados. En el backend utilicé PHP/Laravel, aplicando principios SOLID y un enfoque Event-Driven. Para el FrontEnd se creó un Widget para Vtex Legacy y Vtex IO, utilizando Vanilla JS y ReactJS.</p>",
    tags: [
      "Clean Code",
      "Event-Driven",
      "TDD",
      "SOLID",
      "Laravel",
      "ReactJS",
      "Vtex",
    ],
  },
  {
    title: "Custom Blog The Bar Colombia",
    body: "<p>Se creó para The Bar Colombia un custom blog (<a href='https://co.thebar.com/blog' target='_blank'>https://co.thebar.com/blog</a>) con backend en NodeJS/Express y MongoDB. La interfaz administrativa fue creada en ReactJS para Vtex con componentes customizados e integrados al Admin de Vtex. Clean Code y TDD se aplicaron para asegurar la calidad del código y su estabilidad.</p>",
    tags: [
      "Clean Code",
      "TDD",
      "SOLID",
      "KoaJS",
      "ReactJS",
      "NodeJS",
      "MongoDB",
      "Vtex",
    ],
  },
];
